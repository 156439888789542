import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ValidationResult, WfmTextboxComponent} from '../../../components/wfm-textbox/wfm-textbox.component';
import {SelectItem} from '../../../components/wfm-select/wfm-select.component';
import {DatePipe, FormatWidth, getLocaleDateFormat} from '@angular/common';
import {PlanHelper} from '../../../helpers/plan-helper';
import {Store} from '@ngrx/store';
import {Actions, ofType} from '@ngrx/effects';
import {Subject} from 'rxjs';
import {AppActionTypes, CheckPlanNameExists} from '../../../store/actions';
import {takeUntil} from 'rxjs/operators';
import * as appActions from '../../../store/actions';
import {ScrollerOptions} from 'primeng/api';
import {MultiSelect} from 'primeng/multiselect';

@Component({
  selector: 'create-plan-cxone',
  templateUrl: './create-plan-cxone.component.html',
  styleUrl: './create-plan-cxone.component.scss'
})
export class CreatePlanCXoneComponent implements OnInit, AfterViewInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject();

  planModel = {
    planName: null,
    forecastSource: null,
    forecast: null,
    wemSkills: [] as string[],
    schedulingUnits: [] as string[],
    profile: null,
    sdow: null
  };

  forecastSourceList: Array<SelectItem> = new Array<SelectItem>();
  forecastSourceKeys: string[] = [
    "plan.forecast.source.cxone.wfm",
    "plan.forecast.source.imp"
  ];
  selectedForecastSource: SelectItem = null;
  forecastSourceInitialized: boolean = false;

  startDayOfWeekMap: { [key: string]: string } = {
    "plan.start.day.of.week.sunday": "SUNDAY",
    "plan.start.day.of.week.monday": "MONDAY",
    "plan.start.day.of.week.tuesday": "TUESDAY",
    "plan.start.day.of.week.wednesday": "WEDNESDAY",
    "plan.start.day.of.week.thursday": "THURSDAY",
    "plan.start.day.of.week.friday": "FRIDAY",
    "plan.start.day.of.week.saturday": "SATURDAY"
  };

  startDayOfWeekList: Array<SelectItem> = new Array<SelectItem>();
  _startDayOfWeekListLoading: boolean = false;
  _startDayOfWeekDisabled: boolean;
  selectedStartDayOfWeek: SelectItem;

  _forecastDisabled: boolean;
  _currForecastList: Array<SelectItem> = new Array<SelectItem>();
  _forecastListLoading: boolean = false;
  selectedForecast: SelectItem = null;

  selectedWemSkills = [];
  wemSkillsList: any = [];

  _currProfileList: Array<SelectItem> = new Array<SelectItem>();
  selectedProfile: SelectItem;
  _profileListLoading: boolean = false;

  schedulingUnitList = [];
  selectedSUs = [];
  _suListLoading: boolean = false;

  checkingUniquePlanName = false;
  virtualScrollOptions: ScrollerOptions = {
    autoSize: false,
    scrollHeight: '120px'
  }

  @ViewChild("planName") planNameEl: WfmTextboxComponent;
  @ViewChild("suSelector") suSelector: MultiSelect;
  @ViewChild("skillsMultiSelect") skillSelector: MultiSelect;

  constructor(public activeModal: NgbActiveModal,
              private translate: TranslateService,
              public store: Store<any>,
              private action$: Actions,
              private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.initHandlers();

    this.forecastSourceList = this.forecastSourceKeys.map(key => {
      const value = key === "plan.forecast.source.cxone.wfm" ? "wfm" : "import";
      const item: SelectItem = {label: this.translate.instant(key), value: value, object: key};
      return item;
    });

    this.populateStartDayOfWeekList();

    this._suListLoading = true;
    this.store.dispatch(new appActions.GetSchedulingUnits({}));
  }

  ngAfterViewInit(): void {
    this.planNameEl.validators.push(this.planNameValidator.bind(this));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initHandlers() {
    this.action$
      .pipe(ofType(AppActionTypes.CheckPlanNameExistsReceived), takeUntil(this.unsubscribe$))
      .subscribe(this.planNameExistsReceiveHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.CheckPlanNameExistsFailed), takeUntil(this.unsubscribe$))
      .subscribe(this.planNameExistsFailedHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.GetLongTermForecastsSuccess), takeUntil(this.unsubscribe$))
      .subscribe(this.getLongTermForecastsSuccessHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.GetLongTermForecastsFailed), takeUntil(this.unsubscribe$))
      .subscribe(this.getLongTermForecastsFailedHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.GetSchedulingUnitsSuccess), takeUntil(this.unsubscribe$))
      .subscribe(this.getSchedulingUnitsSuccessHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.GetSchedulingUnitsFailed), takeUntil(this.unsubscribe$))
      .subscribe(this.getSchedulingUnitsFailedHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.GetSkillsAndProfilesSuccess), takeUntil(this.unsubscribe$))
      .subscribe(this.getSkillsAndProfilesSuccessHandler.bind(this));

    this.action$
      .pipe(ofType(AppActionTypes.GetSkillsAndProfilesFailed), takeUntil(this.unsubscribe$))
      .subscribe(this.getSkillsAndProfilesFailedHandler.bind(this));
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  close(result) {
    this.activeModal.close(result);
  }

  createPlan() {
    let request = this.buildRequestPayload();
    this.store.dispatch(new appActions.CreateCXonePlan(request));
    this.close({});
  }

  buildRequestPayload() {
    let request = {
      planName: this.planModel.planName,
      // WFM_FORECAST or IMPORT_FORECAST
      forecastSource: this.planModel.forecastSource.value,
      // forecast OID is a UUID
      forecastOid: this.planModel.forecast.value,
      forecastName: this.planModel.forecast.object?.forecastName,
      startDate: this.planModel.forecast.object?.startDate,
      endDate: this.planModel.forecast.object?.endDate,
      // UUID: skills, schedUnits, profile
      wemSkills: [...this.planModel.wemSkills],
      schedulingUnits: [...this.planModel.schedulingUnits],
      profile: this.planModel.profile.value,
      // SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY
      sdow: this.planModel.sdow?.value
    }
    return request;
  }

  setPlanName(name: string) {
    this.planModel.planName = name;
    this.resetForm();
  }

  resetForm() {
    if (this.planModel?.planName?.trim().length <= 0) {
      this.planModel.forecastSource = this.selectedForecastSource = null;
      this.forecastSourceInitialized = false;
      this.loadForecastList(null);
      this.populateWemSkillsList(null);
      this.updateProfile(null);
      this.resetSchedulingUnits();
      this.updateStartDayOfWeek(null);
    } else if (this.forecastSourceInitialized === false) {
      this.forecastSourceInitialized = true;
      this.updateForecastSource(this.forecastSourceList[0]);
    }
  }

  notifySkillsListener() {
    if (this.skillSelector) {
      this.skillSelector.selectedOptions = this.skillSelector._options().map(opt => opt.value);
      this.skillSelector.updateModel(this.skillSelector.selectedOptions);
      this.skillSelector.onModelChange(this.skillSelector.value);
      this.skillSelector.onChange.emit({originalEvent: null, value: this.skillSelector.value});
    }
  }

  resetSchedulingUnits() {
    if (this.suSelector && this._suListLoading === false) {
      this.suSelector.selectedOptions = [];
      this.suSelector.updateModel([]);
      this.suSelector.onModelChange(this.suSelector.value);
      this.suSelector.onChange.emit({originalEvent: null, value: this.suSelector.value});
    }
  }

  hasValidPlanName() {
    return !this.planNameEl?.hasClientError && this.hasPlanNameWithLength();
  }

  hasPlanNameServerSideError() {
    return this.planNameEl ? this.planNameEl.hasServerError : false;
  }

  hasPlanNameWithLength() {
    return this.planModel?.planName?.trim().length > 0;
  }

  checkPlanNameExists(event: any) {
    if (this.hasValidPlanName()) {
      this.checkingUniquePlanName = true;
      const planName = event.target.value;
      this.store.dispatch(new CheckPlanNameExists({ planName: planName }));
    }
  }

  updateForecastSource($event: SelectItem) {
    this.planModel.forecastSource = this.selectedForecastSource = $event;
    this._forecastListLoading = true;
    this.store.dispatch(new appActions.GetLongTermForecasts(this.selectedForecastSource));
  }

  updateForecast($event: any) {
    this.planModel.forecast = this.selectedForecast = $event;

    if ($event) {
      const payload = {
        forecast_source: this.selectedForecastSource?.value,
        forecast_id: this.selectedForecast?.value
      }

      this._profileListLoading = true;
      this.store.dispatch(new appActions.GetSkillsAndProfiles(payload));
    }
  }

  get forecastList() {
    return this._currForecastList;
  }

  loadForecastList(forecastData: any) {
    this._currForecastList = new Array<SelectItem>();

    if (forecastData) {
      let dateShortFormat = "M/d/yy";
      if (this.translate.currentLang) {
        dateShortFormat = getLocaleDateFormat(this.translate.currentLang, FormatWidth.Short);
      }

      forecastData.forecastEntries.forEach(entry => {
        let startDate = (entry.startDate !== null) ? this.datePipe.transform(new Date(entry.startDate), dateShortFormat, "UTC +0000") : "";
        let endDate = (entry.endDate !== null) ? this.datePipe.transform(new Date(entry.endDate), dateShortFormat, "UTC +0000") : "";
        let fcLabel = this.getLTFLabelForAnElement(entry.forecastName, startDate, endDate);
        const item: SelectItem = {label: fcLabel, value: entry.oid, object: entry};
        this._currForecastList.push(item);
      });
    }

    this.updateForecast(null);
  }

  getLTFLabelForAnElement(name: string, startDate: string, endDate: string): string {
    let fcLabel = name + ` (${startDate}`;
    if (startDate !== "" && endDate !== "") {
      fcLabel = fcLabel + " - ";
    }
    return fcLabel + `${endDate})`;
  }

  updateStartDayOfWeek($event: any) {
    this.planModel.sdow = this.selectedStartDayOfWeek = $event;
  }

  populateSchedulingUnitList(scheduleUnitData: any) {
    // Need to make a deep copy of the scheduling unit data to avoid modifying the original data
    let schedList = JSON.parse(JSON.stringify(scheduleUnitData));

    this.schedulingUnitList = schedList.schedulingUnits?.map((unit: any) => {
      let isSelected = false;
      return {
        value: {
          ...unit,
          label: unit.name
        },
        isSelected
      }
    });
  }

  updateSelectedSUs(event: any) {
    this.schedulingUnitList.forEach(su => {
      su.isSelected = this.selectedSUs.some(selected => selected.oid === su.value.oid);
    });
    this.planModel.schedulingUnits = this.selectedSUs.map(selected => selected.oid);
  }

  updateProfile($event: any) {
    this.planModel.profile = this.selectedProfile = $event;
  }

  get profileList() {
    return this._currProfileList;
  }

  populateProfileList(profileData: any) {
    let profilesList = JSON.parse(JSON.stringify(profileData));

    this._currProfileList = new Array<SelectItem>();
    profilesList.profiles.forEach(profile => {
      const item: SelectItem = {label: profile.name, value: profile.oid, object: profile};
      this._currProfileList.push(item);
    });

    this.updateProfile(null);
  }

  populateWemSkillsList(wemSkillsData: any) {
    if (!wemSkillsData) {
      this.wemSkillsList = [];
    } else {
      // Need to make a deep copy of the WEM skills data to avoid modifying the original data
      let skillsList = JSON.parse(JSON.stringify(wemSkillsData));

      this.wemSkillsList = skillsList.wemSkills?.map((skill: any) => {
        let isSelected = true;
        let isReadOnly = true;
        return {
          value: {
            ...skill,
            label: skill.name,
            disabled: true
          },
          isSelected,
          isReadOnly
        }
      });
    }
    this.planModel.wemSkills = this.wemSkillsList?.map(skill => skill.value.oid);

    setTimeout(() => {
      this.notifySkillsListener();
    }, 500);
  }

  populateStartDayOfWeekList() {
    this.startDayOfWeekList = Object.keys(this.startDayOfWeekMap).map(key => {
      return { label: this.translate.instant(key), value: this.startDayOfWeekMap[key], object: key };
    });
  }

  planNameValidator(name: string) {
    if (name.length < 1) {
      return new ValidationResult(this.translate.instant("field.required.msg"));
    } else if (!PlanHelper.planNameRegEx.test(name)) {
      return new ValidationResult(this.translate.instant("plan.name.invalid"));
    } else {
      return new ValidationResult("");
    }
  }

  planNameExistsReceiveHandler(action: any) {
    this.checkingUniquePlanName = false;
    if (action.payload.exists === true) {
      this.planNameEl.serverSideErrMsgs.push(this.translate.instant("plan.not.unique"));
    } else {
      this.planNameEl.serverSideErrMsgs = this.planNameEl.serverSideErrMsgs.filter(msg => msg != this.translate.instant("plan.not.unique"));
    }
  }

  planNameExistsFailedHandler(action: any) {
    this.checkingUniquePlanName = false;
    this.store.dispatch(new appActions.UnrecoverableError(action));
    this.dismiss();
  }

  getLongTermForecastsSuccessHandler(action: any) {
    this._forecastListLoading = false;
    this.loadForecastList(action.payload);
    // clears out the WEM skills and profiles list along with the selected values
    this.populateWemSkillsList(null);
    this.updateProfile(null);
    this.resetSchedulingUnits();
    this.updateStartDayOfWeek(null);
  }

  getLongTermForecastsFailedHandler(action: any) {
    console.error('failed to retrieve long-term forecasts');
    this._forecastListLoading = false;
    this.store.dispatch(new appActions.UnrecoverableError(action));
    this.dismiss();
  }

  getSchedulingUnitsSuccessHandler(action: any) {
    this._suListLoading = false;
    this.populateSchedulingUnitList(action.payload);
  }

  getSchedulingUnitsFailedHandler(action: any) {
    console.error('failed to retrieve scheduling units');
    this._suListLoading = false;
    this.store.dispatch(new appActions.UnrecoverableError(action));
    this.dismiss();
  }

  getSkillsAndProfilesSuccessHandler(action: any) {
    this._profileListLoading = false;
    this.populateProfileList(action.payload);
    this.populateWemSkillsList(action.payload);
    this.resetSchedulingUnits();
    this.updateStartDayOfWeek(null);
  }

  getSkillsAndProfilesFailedHandler(action: any) {
    console.error('failed to retrieve skills and profiles');
    this._profileListLoading = false;
    this.store.dispatch(new appActions.UnrecoverableError(action));
    this.dismiss();
  }

  isPlanModelValid(): boolean {
    const { planName, forecastSource, forecast, wemSkills, schedulingUnits, profile, sdow } = this.planModel;
    const validModel = [planName, forecastSource, forecast, profile, sdow].every(prop => prop !== null && prop !== '') &&
      wemSkills.length > 0 &&
      schedulingUnits.length > 0;
    const validPlanName = this.hasValidPlanName() && !this.hasPlanNameServerSideError();
    const retVal = validModel && !this.checkingUniquePlanName && validPlanName;
    return retVal;
  }
}
